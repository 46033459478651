import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import Figure from "./figure"
import Slider from "./imageSlider"

import styles from "./gallery.module.scss"

const Gallery = (props) => {
  const colLg = props.columns ? props.columns : 1
  const colMd = props.mediumCols ? props.mediumCols : Math.ceil(colLg / 2)
  const colSm = props.smallCols ? props.smallCols : 1
  const options = {
    settings: {
      autoplaySpeed: 0,
      disableWheelControls: true,
      overlayColor: `rgba(37, 38, 72, 0.9)`
    },
    caption: {
      showCaption: false
    },
    thumbnails: {
      showThumbnails: false
    },
    progressBar: {
      showProgressBar: false
    }
  }

  return (
    <section>
      {props.layout !== "slider" ? (
        <SRLWrapper options={options}>
          <div className={styles.galleryGrid}>
            {props.images && props.images.map(image =>
              <div className={styles.galleryItem} data-lg={colLg} data-md={colMd} data-sm={colSm}>
                <Figure {...image} />
              </div>
            )}
          </div>
        </SRLWrapper>
      ) : (
        <div className={styles.gallerySlider}>
          <Slider {...props} />
        </div>
      )}
    </section>
  )
}

export default Gallery
