import React from "react"
import { connect } from "react-redux"
import { acceptCookies, removeCookies } from "../store/actions"

import styles from "./cookieNotice.module.scss"

const CookieButton = (props) => {

  const onCookiesClick = (evt) => {
    evt.preventDefault()
    props.cookiesAccepted ? props.removeCookies() : props.acceptCookies()
  }

  return (
    <button className={styles.cookieNoticeButton}  onClick={onCookiesClick}>
      <span>{props.cookiesAccepted ? `Remove Cookies` : `Accept Cookies`}</span>
    </button>
  )
}

const mapStateToProps = (state) => ({
  cookiesAccepted: state.cookies.cookiesAccepted,
})

const mapDispatchToProps = (dispatch) => {
  return {
    acceptCookies: () => dispatch(acceptCookies()),
    removeCookies: () => dispatch(removeCookies())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieButton)
