import React from "react"
import Link from "./link"

import styles from "./linkList.module.scss"

const LinkList = props => {
  return (
    <section className={styles.linkList}>
      {props.listTitle && <h4>{props.listTitle}</h4>}
      <ul>
        {props.links.map(link => (
          <li key={link._key}>
            <Link {...link} unstyled />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default LinkList
