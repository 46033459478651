import React from "react"
import Figure from "./figure"

import styles from "./inlineImage.module.scss"
import cx from "classnames"

const InlineImage = props => {
  return (
    <span
      className={cx(
        styles.inlineImage,
        props.image.caption && styles.inlineImageWithCaption
      )}
      data-align={props.alignment}
      data-size={props.size}
    >
      <Figure {...props} />
    </span>
  )
}

export default InlineImage
