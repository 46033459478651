import React from "react"
import { connect } from "react-redux"
import { closeModal } from "../store/actions"

import styles from "./modal.module.scss"

const ModalWrapper = (props, {dispatch}) => {
  const onCloseModal = (evt) => {
    if (evt) evt.preventDefault()
    props.dispatch(closeModal({isOpen: !isOpen}))
  }

  const isOpen = props.isOpen
  return (
    <aside className={`${styles.modal} ${isOpen ? styles.isOpen : ''}`} tabIndex="-1" onClick={(evt) => onCloseModal(evt)}>
      <button className={styles.modalClose} aria-label="Close Modal" aria-labelledby="close-modal">
        <span id="close-modal">Close</span>
      </button>
      <div className={styles.modalInner} role="dialog" id="modal-content" onClick={(evt) => evt.stopPropagation()}>
        {props.children}
      </div>
    </aside>
  )
}

export default connect((state) => ({
  isOpen: state.modal.showModal
}), null)(ModalWrapper)
