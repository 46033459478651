import React from "react"
import RichText from "./richText"

import styles from "./textColumns.module.scss"

const TextColumns = (props) => (
  <div className={styles.textColumns} data-columns={props.columns}>
    <RichText blocks={props.text} />
  </div>
)

export default TextColumns
