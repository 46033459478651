import React from "react"

import styles from "./barGraph.module.scss"

const BarGraph = () => {
  return (
    <div className={styles.barGraph}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 994.2 288.3" enableBackground="new 0 0 994.2 288.3" preserveAspectRatio="xMinYMax meet">
        <g>
          <line className={styles.barLine} fill="none" stroke="#EC6D09" strokeWidth="2" strokeMiterlimit="10" x1="0" y1="79.2" x2="957.5" y2="79.2"/>
          <line className={styles.barLine} fill="none" stroke="#97C672" strokeWidth="2" strokeMiterlimit="10" x1="0" y1="19.2" x2="722.5" y2="19.2"/>
          <line className={styles.barLine} fill="none" stroke="#884895" strokeWidth="2" strokeMiterlimit="10" x1="0" y1="269.2" x2="844.5" y2="269.2"/>
          <line className={styles.barLine} fill="none" stroke="#08BAEC" strokeWidth="2" strokeMiterlimit="10" x1="0" y1="139.2" x2="487.5" y2="139.2"/>
          <line className={styles.barLine} fill="none" stroke="#DE2B87" strokeWidth="2" strokeMiterlimit="10" x1="0" y1="199.2" x2="639.5" y2="199.2"/>
        </g>
        <g>
          <circle className={styles.barCircle} fill="#EC6D09" cx="975.2" cy="79.2" r="11.5"/>
          <circle className={styles.barCircle} fill="#97C672" cx="739.8" cy="19" r="11.5"/>
          <circle className={styles.barCircle} fill="#884895" cx="862.3" cy="269.3" r="11.5"/>
          <circle className={styles.barCircle} fill="#08BAEC" cx="505.6" cy="139.6" r="11.5"/>
          <circle className={styles.barCircle} fill="#DE2B87" cx="657.7" cy="199.1" r="11.5"/>
        </g>
        <g>
          <circle className={styles.barCircleOutline} fill="none" stroke="#EC6D09" strokeWidth="2" strokeMiterlimit="10" cx="975.2" cy="79.2" r="18"/>
          <circle className={styles.barCircleOutline} fill="none" stroke="#97C672" strokeWidth="2" strokeMiterlimit="10" cx="739.8" cy="19" r="18"/>
          <circle className={styles.barCircleOutline} fill="none" stroke="#884895" strokeWidth="2" strokeMiterlimit="10" cx="862.3" cy="269.3" r="18"/>
          <circle className={styles.barCircleOutline} fill="none" stroke="#08BAEC" strokeWidth="2" strokeMiterlimit="10" cx="505.6" cy="139.6" r="18"/>
          <circle className={styles.barCircleOutline} fill="none" stroke="#DE2B87" strokeWidth="2" strokeMiterlimit="10" cx="657.7" cy="199.1" r="18"/>
        </g>
      </svg>
    </div>
  )
}

export default BarGraph
