import React, { useRef, useEffect } from "react"
import scrollToElement from "scroll-to-element"
import { StaticQuery, graphql, Link } from "gatsby"
import { connect } from "react-redux"
import { useViewport } from "../helpers/viewportTest"
import { mobileNavToggle } from "../store/actions"
import MenuButton from "./menuButton"
import Figure from "./figure"

import styles from "./nav.module.scss"

const Nav = ({ dispatch, isOpen, dist }) => {
  const { width } = useViewport()
  const breakpoint = 1023

  // Store Dropdown Triggers as refs
  const solutionsTrigger = useRef()
  const productTrigger = useRef()
  const expertiseTrigger = useRef()
  const resourcesTrigger = useRef()

  const renderLogos = logos => {
    return logos.map(item => (
      <li key={item._id} role="none">
        {!item.link ? (
          <Figure {...item.logo} />
        ) : item.link.startsWith("http") ? (
          <a href={item.link} target="_blank">
            <Figure {...item.logo} />
          </a>
        ) : (
          <Link to={item.link} onClick={resetNav}>
            <Figure {...item.logo} />
          </Link>
        )}
      </li>
    ))
  }

  const setupKeydown = () => {
    ;[solutionsTrigger, productTrigger, expertiseTrigger, resourcesTrigger].map(
      trigger => {
        trigger.current.addEventListener("keydown", handleKeydown)
        trigger.current.addEventListener("focus", handleFocus)
      }
    )
  }

  const teardownKeydown = () => {
    ;[
      solutionsTrigger,
      productTrigger,
      expertiseTrigger,
      resourcesTrigger,
    ].map(trigger =>
      trigger.current.removeEventListener("keydown", handleKeydown)
    )
  }

  const setupClick = () => {
    ;[
      solutionsTrigger,
      productTrigger,
      expertiseTrigger,
      resourcesTrigger,
    ].map(trigger =>
      trigger.current.addEventListener("click", handleDropdownClick)
    )
  }

  const teardownClick = () => {
    ;[
      solutionsTrigger,
      productTrigger,
      expertiseTrigger,
      resourcesTrigger,
    ].map(trigger =>
      trigger.current.removeEventListener("click", handleDropdownClick)
    )
  }

  const resetDropdowns = () => {
    ;[
      solutionsTrigger,
      productTrigger,
      expertiseTrigger,
      resourcesTrigger,
    ].map(trigger => trigger.current.setAttribute("aria-expanded", "false"))
  }

  const setupNav = () => {
    if (width > breakpoint) {
      teardownClick()
      setupKeydown()
    } else {
      teardownKeydown()
      setupClick()
    }
  }

  const resetNav = () => {
    resetDropdowns()
    dispatch(mobileNavToggle({ isOpen: false }))
  }

  const handleKeydown = evt => {
    if (evt.keyCode === 13) {
      evt.preventDefault()
      resetDropdowns()
      evt.currentTarget.setAttribute("aria-expanded", "true")
      evt.currentTarget.querySelector("a").focus()

      let dropdown = evt.currentTarget.querySelector("[id*='dropdown']")
      setupDropdownKeydown(dropdown)
    }
    if (evt.keyCode === 27) {
      evt.currentTarget.setAttribute("aria-expanded", "false")
      evt.currentTarget.focus()
    }
  }

  const setupDropdownKeydown = parent => {
    const links = parent.querySelectorAll("a")
    links.forEach((el, i) => {
      el.addEventListener("keydown", evt => {
        if (evt.keyCode === 40 || evt.keyCode === 39) {
          let next = links[i + 1] || null
          evt.preventDefault()
          next !== null && next.focus()
        }
        if (evt.keyCode === 38 || evt.keyCode === 37) {
          let prev = links[i - 1] || null
          evt.preventDefault()
          prev !== null && prev.focus()
        }
        if (evt.keyCode === 13) {
          evt.stopPropagation()
        }
      })
    })
  }

  const handleFocus = evt => {
    ;[
      solutionsTrigger,
      productTrigger,
      expertiseTrigger,
      resourcesTrigger,
    ].map(trigger => trigger.current.setAttribute("aria-expanded", "false"))
  }

  const handleScrollClick = (evt, target) => {
    scrollToElement(target, {
      offset: -90, // Offset a fixed header if you please
      duration: 0,
    })

    resetNav()
  }

  const handleClick = () => {
    dispatch(mobileNavToggle({ isOpen: !isOpen }))
  }

  const handleDropdownClick = evt => {
    let button = evt.currentTarget

    if (button.getAttribute("aria-expanded") === "false") resetDropdowns()
    button.getAttribute("aria-expanded") === "true"
      ? button.setAttribute("aria-expanded", "false")
      : button.setAttribute("aria-expanded", "true")
  }

  useEffect(() => {
    setupNav()
    return () => {
      teardownClick()
      teardownKeydown()
    }
  })

  return (
    <StaticQuery
      query={graphql`
        {
          sanityNavigation {
            _rawIndustry
            _rawProgram
            _rawDatasource(resolveReferences: { maxDepth: 10 })
            _rawProduct
            _rawResources
            _rawExpertise
            blogsLink
            _rawBlogs
          }
        }
      `}
      render={data => (
        <>
          <MenuButton handleClick={handleClick} isOpen={isOpen} />
          <nav
            id="menu"
            className={styles.nav}
            aria-label="Watershed Main"
            data-mobile-open={isOpen}
          >
            <ul role="menubar" aria-label="Watershed Main">
              <li
                className={styles.navItem}
                role="menuitem"
                aria-haspopup="true"
                aria-expanded="false"
                tabIndex="0"
                ref={solutionsTrigger}
              >
                <span className={styles.navLink}>Solutions</span>
                <div
                  className={`${styles.navDropdown} ${styles.navDropdownMega}`}
                  id="solutions-dropdown"
                >
                  <div className={styles.navDropdownMegaAside}>
                    <h3 className={styles.navDropdownTitle}>
                      {data.sanityNavigation._rawDatasource.title ||
                        `Solutions by Data Source`}
                    </h3>
                    <p>{data.sanityNavigation._rawDatasource.description}</p>
                    <ul>
                      {renderLogos(data.sanityNavigation._rawDatasource.links)}
                    </ul>
                    <Link to="/solutions/data-sources/" onClick={resetNav}>
                      See all data source solutions
                    </Link>
                  </div>
                  <div className={styles.navDropdownMegaInner}>
                    <h3
                      className={styles.navDropdownTitle}
                      id="industrySolutionsMenu"
                    >
                      Solutions by Industry
                    </h3>
                    <ul
                      aria-label="Solutions By Industry"
                      aria-labelledby="industrySolutionsMenu"
                    >
                      {data.sanityNavigation._rawIndustry.links.map(link => (
                        <li key={link._key} role="none">
                          <Link to={link.link} onClick={resetNav}>
                            {link.linkLabel}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <Link
                      to="/solutions/#industries"
                      onClick={evt => handleScrollClick(evt, "#industries")}
                    >
                      See all industry solutions
                    </Link>
                    <h3
                      className={styles.navDropdownTitle}
                      id="programSolutionsMenu"
                    >
                      Solutions by Program
                    </h3>
                    <ul
                      aria-label="Solutions By Program"
                      aria-labelledby="programSolutionsMenu"
                    >
                      {data.sanityNavigation._rawProgram.links.map(link => (
                        <li key={link._key} role="none">
                          <Link to={link.link} onClick={resetNav}>
                            {link.linkLabel}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <Link
                      to="/solutions/#programs"
                      onClick={evt => handleScrollClick(evt, "#programs")}
                    >
                      See all program solutions
                    </Link>
                  </div>
                </div>
              </li>
              <li
                className={styles.navItem}
                role="menuitem"
                aria-haspopup="true"
                aria-expanded="false"
                tabIndex="0"
                ref={productTrigger}
              >
                <span className={styles.navLink}>Product</span>
                <ul className={styles.navDropdown} id="product-dropdown">
                  {data.sanityNavigation._rawProduct.links.map(link => (
                    <li key={link._key} role="none">
                      <Link to={link.link} onClick={resetNav}>
                        {link.linkLabel}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li
                className={styles.navItem}
                role="menuitem"
                aria-haspopup="true"
                aria-expanded="false"
                tabIndex="0"
                ref={expertiseTrigger}
              >
                <span className={styles.navLink}>Expertise</span>
                <ul className={styles.navDropdown} id="expertise-dropdown">
                  {data.sanityNavigation._rawExpertise.links.map(link => (
                    <li key={link._key} role="none">
                      <Link to={link.link} onClick={resetNav}>
                        {link.linkLabel}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li
                className={styles.navItem}
                role="menuitem"
                aria-haspopup="true"
                aria-controls="resources-dropdown"
                aria-expanded="false"
                tabIndex="0"
                ref={resourcesTrigger}
              >
                <span className={styles.navLink}>Resources</span>
                <ul
                  className={styles.navDropdown}
                  aria-label="Resources Dropdown"
                  id="resources-dropdown"
                >
                  <li role="none">
                    <Link
                      to={data.sanityNavigation.blogsLink}
                      role="menuitem"
                      onClick={resetNav}
                    >
                      Blogs
                    </Link>
                    <ul>
                      {data.sanityNavigation._rawBlogs.links.map(link => (
                        <li key={link._key} role="none">
                          <Link
                            to={link.link}
                            role="menuitem"
                            onClick={resetNav}
                          >
                            {link.linkLabel}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  {data.sanityNavigation._rawResources.links.map(link => (
                    <li key={link._key} role="none">
                      <Link to={link.link} role="menuitem" onClick={resetNav}>
                        {link.linkLabel}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className={styles.navButton}>
                <Link to="/request-a-demo/" onClick={resetNav}>
                  Request a demo
                </Link>
              </li>
            </ul>
            <div className={styles.navSignIn}>
              <a
                className={styles.headerLink}
                href="https://watershedlrs.com/app/outside.html#/signin"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Sign in
              </a>
            </div>
          </nav>
        </>
      )}
    />
  )
}

export default connect(
  state => ({
    isOpen: state.mobileNav.mobileNavOpen,
    dist: state.mobileNav.offsetTop,
  }),
  null
)(Nav)
