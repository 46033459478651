import BaseBlockContent from "@sanity/block-content-to-react"
import React from "react"
import InlineImage from "./inlineImage"
import BlockImage from "./blockImage"
import TextColumns from "./textColumns"
import Callout from "./callout"
import InlineTestimonial from "./inlineTestimonial"
import LinkList from "./linkList"
import Iframe from "./iframeEmbed"
import IframeGroup from "./iframeGroup"
import ProfileCards from "./profileCards"
import CheckList from "./checkList"
import FormattedLink from "./link"
import CookieButton from "./cookieButton"
import Gallery from "./gallery"
import Table from "./table"

import styles from "./richText.module.scss"

const serializers = {
  types: {
    block: props => {
      if (props.node.style === "stat")
        return <span className={styles.richTextStat}>{props.children}</span>

      if (props.node.style === "note")
        return <span className={styles.richTextNote}>{props.children}</span>

      if (props.node.style === "largeText") {
        return (
          <span className={styles.richTextLargeText}>{props.children}</span>
        )
      }

      if (props.children.indexOf("[manage-cookie-acceptance]") !== -1)
        return <CookieButton />

      return BaseBlockContent.defaultSerializers.types.block(props)
    },
    inlineImage(props) {
      return <InlineImage {...props.node} />
    },
    blockImage(props) {
      return <BlockImage {...props.node} />
    },
    textColumns(props) {
      return <TextColumns {...props.node} />
    },
    callout(props) {
      return <Callout {...props.node} />
    },
    testimonial(props) {
      return <InlineTestimonial {...props.node.testimonial} />
    },
    linkList(props) {
      return <LinkList {...props.node} />
    },
    hr() {
      return <hr />
    },
    iframeEmbed(props) {
      return <Iframe {...props.node} />
    },
    iframeGroup(props) {
      return <IframeGroup {...props.node} />
    },
    videoEmbed(props) {
      return <Iframe {...props.node} />
    },
    profiles(props) {
      return <ProfileCards {...props.node} />
    },
    gallery(props) {
      return <Gallery {...props.node} />
    },
    codeBlock(props) {
      return (
        <div
          className={styles.preFormattedCode}
          dangerouslySetInnerHTML={{ __html: `${props.node.code}` }}
        />
      )
    },
    table(props) {
      return <Table {...props.node} />
    },
  },
  marks: {
    link: ({ mark, children }) => {
      return (
        <FormattedLink
          {...mark}
          linkUrl={mark.href || mark.link}
          linkLabel={children}
          unstyled
        />
      )
    },
    sup: ({ children }) => {
      return <sup>{children}</sup>
    },
    highlight: ({ children }) => {
      return (
        <mark style={{ backgroundColor: `#b5dff7`, padding: `0 3px` }}>
          {children}
        </mark>
      )
    },
    caps: ({ children }) => {
      return <span style={{ textTransform: `uppercase` }}>{children}</span>
    },
    id: ({ mark, children }) => {
      return (
        <span id={mark.id.current} style={{ scrollMarginTop: `100px` }}>
          {children}
        </span>
      )
    },
    left: ({ mark, children }) => {
      return (
        <span style={{ display: `block`, textAlign: mark }}>{children}</span>
      )
    },
    center: ({ mark, children }) => {
      return (
        <span style={{ display: `block`, textAlign: mark }}>{children}</span>
      )
    },
    right: ({ mark, children }) => {
      return (
        <span style={{ display: `block`, textAlign: mark }}>{children}</span>
      )
    },
  },
  list: props => {
    if (props.type === "roman") {
      return <CheckList listItems={props.children} />
    }

    return BaseBlockContent.defaultSerializers.list(props)
  },
}

const RichText = ({ blocks, align }) => (
  <div className={styles.richText} data-align={align}>
    <BaseBlockContent blocks={blocks} serializers={serializers} />
  </div>
)

export default RichText
