import React from "react"

import styles from "./menuButton.module.scss"

const MenuButton = (props) => (
  <button className={styles.menuButton} aria-expanded={props.isOpen} aria-controls="menu" onClick={props.handleClick}>
    <span className={styles.menuLabel}>Menu</span>
    <span className={styles.menuIcon}></span>
  </button>
)

export default MenuButton
