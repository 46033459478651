import React from "react"
import Figure from "./figure"
import cx from "classnames"

import Arrow from "../../assets/images/arrow"

import styles from "./blockImage.module.scss"

const BlockImage = props => {
  return (
    <>
      {props.imageLink ? (
        <a
          className={cx(
            styles.blockImage,
            props?.image?.caption && styles.blockImageWithCaption
          )}
          href={props.imageLink}
          data-align={props.alignment}
          data-size={props.size}
          target={props.newTab ? "_blank" : "_self"}
        >
          <Figure {...props} />
          {!props.removeArrow && (
            <span className={styles.arrowContainer}>
              <Arrow className={styles.arrow} />
            </span>
          )}
        </a>
      ) : (
        <div
          className={cx(
            styles.blockImage,
            props.image.caption && styles.blockImageWithCaption
          )}
          data-align={props.alignment}
          data-size={props.size}
        >
          <Figure {...props} />
        </div>
      )}
    </>
  )
}

export default BlockImage
