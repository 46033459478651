import React from "react"

import styles from "./radarGraph2.module.scss"

const RadarGraph2 = () => {
  return (
    <div className={styles.radarGraph}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 486 488" enableBackground="new 0 0 486 488">
        <g className={styles.group}>
          <polygon opacity="8.000000e-02" fill="#08BAEC" points="247.5,482.5 480.5,293.5 353.5,71.5 115.5,35.5 37.5,285.5   "/>
          <path fill="none" stroke="#08BAEC" strokeMiterlimit="10" d="M247.5,482.5 480.5,293.5 353.5,71.5 115.5,35.5 37.5,285.5 247.5,482.5"/>
          <circle fill="#08BAEC" cx="353.5" cy="71.5" r="5.5"/>
          <circle fill="#08BAEC" cx="480.5" cy="292.5" r="5.5"/>
          <circle fill="#08BAEC" cx="247.5" cy="482.5" r="5.5"/>
          <circle fill="#08BAEC" cx="37.5" cy="285.5" r="5.5"/>
          <circle fill="#08BAEC" cx="115.5" cy="35.5" r="5.5"/>
        </g>
        <g className={styles.group}>
          <polygon opacity="8.000000e-02" fill="#EC6D09" points="247.5,446.5 454.5,284.5 401.5,4.5 131.5,58.5 4.5,296.5   "/>
          <path fill="none" stroke="#EC6D09" strokeMiterlimit="10" d="M247.5,446.5 454.5,284.5 401.5,4.5 131.5,58.5 4.5,296.5 247.5,446.5"/>
          <circle fill="#EC6D09" cx="401.5" cy="5.5" r="5.5"/>
          <circle fill="#EC6D09" cx="454.5" cy="284.5" r="5.5"/>
          <circle fill="#EC6D09" cx="247.5" cy="446.5" r="5.5"/>
          <circle fill="#EC6D09" cx="5.5" cy="295.5" r="5.5"/>
          <circle fill="#EC6D09" cx="131.5" cy="57.5" r="5.5"/>
        </g>
      </svg>
    </div>
  )
}

export default RadarGraph2
