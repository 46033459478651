import React from "react"
import BarGraph from "./barGraph"
import BarGraph2 from "./barGraph2"
import AngularLineGraph from "./angularLineGraph"
import RadarGraph from "./radarGraph"
import RadarGraph2 from "./radarGraph2"
import RadarPinkGraph from "./radarPinkGraph"
import RadarWhiteGraph from "./radarWhiteGraph"

const HeroAnimations = (props) => {
  switch (props.graph) {
    case "bar":
      return <BarGraph />

    case "bar2":
      return <BarGraph2 />

    case "angularLine":
      return <AngularLineGraph />

    case "radar":
      return <RadarGraph />

    case "radar2":
      return <RadarGraph2 />

    case "radarPink":
      return <RadarPinkGraph />

    case "radarWhite":
      return <RadarWhiteGraph />

    default:
      return null
  }
}

export default HeroAnimations
