import React from "react"

import styles from "./angularLineGraph.module.scss"

const AngularLineGraph = () => {
  return (
    <div className={styles.angularLineGraph}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       viewBox="0 0 670.1 338.6" enableBackground="new 0 0 670.1 338.6" preserveAspectRatio="xMinYMax slice">
        <g className={styles.group}>
          <path fill="none" stroke="#97C672" strokeMiterlimit="10" d="M4.5,333.4 115.1,284.7 225.1,3.3 335.1,271.3 445.1,268.5 555.1,257.4 665.1,292.7" />
          <polygon className={styles.point} fill="#97C672" points="664.8,297.5 669.9,288.7 659.8,288.7   "/>
          <polygon className={styles.point} fill="#97C672" points="555.6,263.8 560.6,255 550.5,255   "/>
          <polygon className={styles.point} fill="#97C672" points="445,273.8 450,265 439.9,265   "/>
          <polygon className={styles.point} fill="#97C672" points="335.2,276.5 340.3,267.7 330.1,267.7   "/>
          <polygon className={styles.point} fill="#97C672" points="225.2,8.8 230.2,0 220.1,0   "/>
          <polygon className={styles.point} fill="#97C672" points="115.6,289.3 120.7,280.5 110.5,280.5   "/>
          <polygon className={styles.point} fill="#97C672" points="5.3,338.6 10.3,329.8 0.2,329.8  "/>
        </g>
        <g className={styles.group}>
          <path fill="none" stroke="#08BAEC" strokeMiterlimit="10" d="M4.5,283.4 115.1,268.7 225.1,64.3 335.1,231.3 445.1,299.5 555.1,276.4 665.1,213.7" />
          <rect className={styles.point} x="662.1" y="210.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 43.8177 533.1287)" fill="#08BAEC" width="6.6" height="6.6"/>
          <rect className={styles.point} x="551.7" y="273.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -33.1145 473.5076)" fill="#08BAEC" width="6.6" height="6.6"/>
          <rect className={styles.point} x="441.7" y="296.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -81.4999 402.4415)" fill="#08BAEC" width="6.6" height="6.6"/>
          <rect className={styles.point} x="331.8" y="228" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -65.4265 304.6943)" fill="#08BAEC" width="6.6" height="6.6"/>
          <rect className={styles.point} x="222.1" y="61" transform="matrix(0.7071 -0.7071 0.7071 0.7071 20.5255 178.2012)" fill="#08BAEC" width="6.6" height="6.6"/>
          <rect className={styles.point} x="111.4" y="265" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -156.1002 159.6617)" fill="#08BAEC" width="6.6" height="6.6"/>
          <rect className={styles.point} x="1.9" y="279.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -198.7435 86.6481)" fill="#08BAEC" width="6.6" height="6.6"/>
        </g>

        <g className={styles.group}>
          <path fill="none" stroke="#ec6d09" strokeMiterlimit="10" d="M4.5,303.4 115.1,318.7 225.1,42.3 335.1,321.3 445.1,256.5 555.1,307.4 665.1,243.7" />
          <circle className={styles.point} fill="#EC6D09" cx="664.6" cy="244.2" r="3"/>
          <circle className={styles.point} fill="#EC6D09" cx="555.6" cy="307.2" r="3"/>
          <circle className={styles.point} fill="#EC6D09" cx="444.6" cy="257.2" r="3"/>
          <circle className={styles.point} fill="#EC6D09" cx="334.6" cy="321.2" r="3"/>
          <circle className={styles.point} fill="#EC6D09" cx="225.6" cy="43.2" r="3"/>
          <circle className={styles.point} fill="#EC6D09" cx="114.6" cy="318.2" r="3"/>
          <circle className={styles.point} fill="#EC6D09" cx="4.6" cy="303.2" r="3"/>
        </g>
        <g className={styles.group}>
          <path fill="none" stroke="#DE2B87" strokeMiterlimit="10" d="M4.5,323.4 115.1,298.7 225.1,82.3 335.1,301.3 445.1,276.5 555.1,297.4 665.1,263.7" />
          <polygon className={styles.point} fill="#DE2B87" points="664.6,259.3 659.6,268 669.6,268  "/>
          <polygon className={styles.point} fill="#DE2B87" points="555.5,291.9 550.5,300.5 560.5,300.5  "/>
          <polygon className={styles.point} fill="#DE2B87" points="444.7,271.5 439.7,280.1 449.7,280.1  "/>
          <polygon className={styles.point} fill="#DE2B87" points="335.2,295.7 330.2,304.3 340.2,304.3  "/>
          <polygon className={styles.point} fill="#DE2B87" points="224.9,77.6 219.9,86.3 229.9,86.3   "/>
          <polygon className={styles.point} fill="#DE2B87" points="115.6,292.2 110.6,300.8 120.6,300.8  "/>
          <polygon className={styles.point} fill="#DE2B87" points="5,318.4 0,327 10,327   "/>
        </g>
        </svg>
    </div>
  )
}

export default AngularLineGraph
