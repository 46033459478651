import React from "react"
import Logo from "./logo"
import Nav from "./nav"
import { Link } from "gatsby"

import styles from "./header.module.scss"
import cx from "classnames"

const Header = ({ theme, transition, navTop }) => (
  <header
    className={styles.header}
    data-theme={theme}
    data-transition={transition}
  >
    <div className={styles.headerInner}>
      <Logo />
      <Nav />
      <Link
        to={`/contact-us`}
        className={cx(styles.headerLink, styles.contactUs)}
      >
        Contact us
      </Link> 
      <a
        className={styles.headerLink}
        href="https://watershedlrs.com/app/outside.html#/signin"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        Sign in
      </a>
    </div>
  </header>
)

export default Header
