import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { connect } from "react-redux"
import { acceptCookies } from "../store/actions"
import RichText from "./richText"

import styles from "./cookieNotice.module.scss"

const CookieNotice = (props) => (
  <StaticQuery
    query={graphql`
      {
        sanityConfig {
          _rawCookieMessage
        }
      }
    `}
    render={data => {
      const onAcceptCookiesClick = (evt) => {
        evt.preventDefault()
        props.acceptCookies()
      }
      return (
        <div className={styles.cookieNotice} data-show={props.showNotice}>
          <RichText blocks={data.sanityConfig._rawCookieMessage} />
          <button className={styles.cookieNoticeButton} onClick={onAcceptCookiesClick}>
            <span>Accept Cookies</span>
          </button>
        </div>
      )
    }}
  />
)

const mapDispatchToProps = (dispatch) => {
  return {
    acceptCookies: () => dispatch(acceptCookies())
  }
}

export default connect(null, mapDispatchToProps)(CookieNotice)
