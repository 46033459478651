import React from "react"

import styles from "./barGraph2.module.scss"

const BarGraph2 = () => {
  return (
    <div className={styles.barGraph}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 1054 404" enableBackground="new 0 0 1054 404" preserveAspectRatio="xMinYMax meet">
        <g className={styles.group}>
          <rect y="371" fill="#EC6D09" width="785" height="33"/>
          <rect y="318" fill="#EC6D09" width="855" height="33"/>
          <rect y="265" fill="#EC6D09" width="1011" height="33"/>
          <rect y="212" fill="#EC6D09" width="647" height="33"/>
          <rect y="159" fill="#EC6D09" width="801" height="33"/>
          <rect y="106" fill="#EC6D09" width="923" height="33"/>
          <rect y="53" fill="#EC6D09" width="466" height="33"/>
          <rect fill="#EC6D09" width="1054" height="33"/>
        </g>
        <g className={styles.group}>
          <rect y="371" fill="#DE2B87" width="651.7" height="33"/>
          <rect y="318" fill="#DE2B87" width="709.8" height="33"/>
          <rect y="265" fill="#DE2B87" width="839.3" height="33"/>
          <rect y="212" fill="#DE2B87" width="537.1" height="33"/>
          <rect y="159" fill="#DE2B87" width="665" height="33"/>
          <rect y="106" fill="#DE2B87" width="766.2" height="33"/>
          <rect y="53" fill="#DE2B87" width="386.9" height="33"/>
          <rect fill="#DE2B87" width="875" height="33"/>
        </g>
        <g className={styles.group}>
          <rect y="371" fill="#025FA2" width="537" height="33"/>
          <rect y="318" fill="#025FA2" width="584.9" height="33"/>
          <rect y="265" fill="#025FA2" width="691.6" height="33"/>
          <rect y="212" fill="#025FA2" width="442.6" height="33"/>
          <rect y="159" fill="#025FA2" width="547.9" height="33"/>
          <rect y="106" fill="#025FA2" width="631.4" height="33"/>
          <rect y="53" fill="#025FA2" width="318.8" height="33"/>
          <rect fill="#025FA2" width="721" height="33"/>
        </g>
      </svg>
    </div>
  )
}

export default BarGraph2
