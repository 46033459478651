import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { headerTransition, checkCookies } from "../store/actions"
import Header from "./header"
import Footer from "./footer"
import CookieNotice from "./cookieNotice"
import ModalConductor from "../modals/modalConductor"
import SimpleReactLightbox from "simple-react-lightbox"

import "../_global/base.scss"
import "../_global/grid.scss"
import "./layout.scss"

class Layout extends Component {
  componentDidMount() {
    this.props.checkCookies()
    this.onScroll()
    window.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.onScroll)
    window.removeEventListener("scroll", this.onScroll)
  }

  onScroll = () => {
    if (window.pageYOffset > 60) {
      this.props.dispatchHeaderTransition(true)
    } else {
      this.props.dispatchHeaderTransition(false)
    }
  }

  render() {
    return (
      <SimpleReactLightbox>
        <Helmet>
          <script type="text/javascript" src="/js/modernizr.js" defer></script>
          <body data-mobilenavopen={this.props.navIsOpen} />
        </Helmet>
        <div className="pageWrapper">
          <Header
            theme={this.props.theme}
            transition={this.props.headerTransition}
          />
          <main>{this.props.children}</main>
          <Footer />
        </div>
        {!this.props.cookiesAccepted && (
          <CookieNotice showNotice={this.props.showNotice} />
        )}
        <ModalConductor />
      </SimpleReactLightbox>
    )
  }
}

const mapStateToProps = state => ({
  headerTransition: state.header.headerTransition,
  navIsOpen: state.mobileNav.mobileNavOpen,
  cookiesAccepted: state.cookies.cookiesAccepted,
  showNotice: state.cookies.showNotice,
})

const mapDispatchToProps = dispatch => {
  return {
    dispatchHeaderTransition: payload => dispatch(headerTransition(payload)),
    checkCookies: () => dispatch(checkCookies()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
