import React from "react"
import Icon from "./icon"
import RichText from "./richText"

import styles from "./callout.module.scss"

const Callout = (props) => {
  return (
    <div className={`${styles.callout} ${props.extra ? styles.calloutExtra : ''}`}>
      {props.icon && <Icon icon={props.icon} />}
      <RichText blocks={props.text} />
    </div>
  )
}

export default Callout
