import React from "react"
import Figure from "./figure"

import styles from "./profileCard.module.scss"

const ProfileCards = (props) => {
  return (
    <>
      {props.profiles.map(profile =>
        <div className={styles.profileCard} key={profile._id}>
          {profile.image && <Figure {...profile.image} />}
          <div className={styles.profileCardBody}>
            <h4 className={styles.profileCardTitle}>{profile.name}</h4>
            {(profile.position || profile.organisation) && <span>{profile.position}{profile.position && profile.organisation ? ', ' : null}{profile.organisation}</span>}
            {profile.bio && <p>{profile.bio}</p>}
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileCards
