import React from "react"
import { Link } from "gatsby"
import { LinkTest, LinkFormat } from "../helpers/linkTest"
import { connect } from "react-redux"
import { showVideoModal } from "../store/actions"

import styles from "./link.module.scss"

const FormattedLink = (props, {dispatch, isOpen, url}) => {
  const handleClick = (evt) => {
    evt.preventDefault()
    props.dispatch(showVideoModal({isOpen: !isOpen, url: props.linkUrl}))
  }
  const clickCallback = props.linkType === "videoLink" ? {["onClick"]: (evt) => { handleClick(evt) }} : null
  const linkType = LinkTest(props.linkUrl)
  const newTab = props.newTab ? {["target"]: "_blank", ["rel"]: "noopener noreferrer"} : null
  const noFollow = props.noFollow ? {["rel"]: "nofollow noopener noreferrer"} : null
  switch (linkType) {
    case 'internal':
      return <Link className={props.unstyled ? null : styles.link} to={LinkFormat(props.linkUrl)} {...newTab} {...noFollow}>{props.linkLabel}</Link>

    case 'email':
      return <a className={props.unstyled ? null : styles.link} href={LinkFormat(props.linkUrl)}>{props.linkLabel}</a>

    default:
      return <a className={props.unstyled ? null : styles.link} href={props.linkUrl} {...newTab} {...noFollow} {...clickCallback}>{props.linkLabel}</a>
  }
}

export default connect((state) => ({
  isOpen: state.modal.showModal,
  url: state.modal.url
}), null)(FormattedLink)
