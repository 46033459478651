import React from "react"
import ModalWrapper from "./modalWrapper"
import VideoEmbed from "../components/videoEmbed"

const VideoModal = (props) => {
  return (
    <ModalWrapper {...props}>
      <VideoEmbed url={props.url} />
    </ModalWrapper>
  )
}

export default VideoModal