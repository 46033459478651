import React from "react"

import styles from "./checkList.module.scss"

const CheckList = (props) => {
  return (
    <ul className={styles.checkList}>
      {props.listItems.map(item =>
        <li key={item._key}>
          {item.props.children}
        </li>
      )}
    </ul>
  )
}

export default CheckList

