import React from "react"
import HeroAnimations from "../heroAnimations"
import RichText from "./richText"
import Buttons from "./buttons"

import styles from "./hero.module.scss"

const Hero = (props) => {
  return (
    <section className={styles.hero} data-theme={props.theme}>
      <div className={styles.heroInner}>
        <div className={styles.heroContentWrapper}>
          <div className={styles.heroContent}>
            <h1>{props.heroTitle}</h1>
            {props.heroContent && <RichText blocks={props.heroContent} />}
            {props.links && <Buttons buttons={props.links} />}
          </div>
          {props.video &&
            <div className={styles.heroVideo}>
              <div className={styles.heroVideoInner}>
                <video poster={props.poster} muted autoPlay loop>
                  <source src={props.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          }
        </div>
      </div>
      {props.graph && <div className={styles.heroBackground}><HeroAnimations graph={props.graph} /></div>}
    </section>
  )
}

export default Hero
