export const headerTransition = (payload) => {
  return { type: "HEADER_TRANSITION", payload }
}

export const mobileNavToggle = (payload) => {
  return { type: "MOBILE_NAV_TOGGLE", payload }
}

export const showVideoModal = (payload) => {
  return { type: "SHOW_VIDEO_MODAL", payload }
}

export const closeModal = (payload) => {
  return { type: "CLOSE_MODAL", payload }
}

const onCheckCookies = (name) => {
  const allCookies = document.cookie.split(';')
  for(let i = 0; i < allCookies.length; i++) {
    let cookie = allCookies[i]
    while (cookie.charAt(0) === " ") { cookie = cookie.substring(1, cookie.length) }
    if (cookie.indexOf(name) === 0) {
      return {
        cookiesAccepted: true,
        showNotice: false
      }
    }
  }

  return {
    cookiesAccepted: false,
    showNotice: true
  }
}

const onAcceptCookies = () => {
  const date = new Date()
  date.setTime(date.getTime() + (365*24*60*60*1000))
  const expires = "expires=" + date.toUTCString()
  document.cookie = "cookiesAccepted=true; " + expires + "; path=/"
  document.cookie = "pi_opt_in271292=true; " + expires + "; path=/"
  window.location.reload()
}

const onRemoveCookies = () => {
  if (onCheckCookies("cookiesAccepted")) {
    document.cookie = "cookiesAccepted=true; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/"
    window.location.reload()
  }
}

export const acceptCookies = (payload) => {
  return (dispatch) => {
    onAcceptCookies()
    const accepted = onCheckCookies("cookiesAccepted")
    dispatch({ type: "ACCEPT_COOKIES", accepted })
  }
}

export const removeCookies = (payload) => {
  return (dispatch) => {
    onRemoveCookies()
    const accepted = onCheckCookies("cookiesAccepted")
    dispatch({ type: "REMOVE_COOKIES", accepted })
  }
}

export const checkCookies = () => {
  return (dispatch) => {
    const accepted = onCheckCookies("cookiesAccepted")
    dispatch({ type: "CHECK_COOKIES", accepted })
  }
}

