const email = /\S+@\S+\.\S+/
const external = new RegExp('^(?:[a-z]+:)?//', 'i')

export const LinkTest = (link) => {
  if (typeof link !== 'string') return
  if (email.test(link)) return 'email'
  if (!external.test(link)) return 'internal'

  return 'external'
}

export const LinkFormat = (link) => {
  const linkType = LinkTest(link)
  switch (linkType) {
    case "email":
      const email = link.replace(/^mailto:([^?]+).*/, '$1')
      return `mailto:${email}`

    case "internal":
      return link.charAt(0) !== '/' ? `/${link}` : link

    default:
      return link
  }
}
