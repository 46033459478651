import React, { Component } from "react"
import { SRLWrapper } from "simple-react-lightbox"
import Slick from "react-slick"
import Figure from "./figure"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./imageSlider.scss"

class ImageSlider extends Component {
  render() {
    const ArrowLeft = ({currentSlide, slideCount, children, ...props}) => {
      return <button {...props} className="sliderArrow prev" aria-label="Previous slide" />
    }

    const ArrowRight = ({currentSlide, slideCount, children, ...props}) => {
      return <button {...props} className="sliderArrow next" aria-label="Next slide" />
    }

    const colLg = this.props.columns ? this.props.columns : 1
    const colMd = this.props.mediumCols ? this.props.mediumCols : Math.ceil(colLg / 2)
    const colSm = this.props.smallCols ? this.props.smallCols : 1

    const settings = {
      prevArrow: <ArrowLeft />,
      nextArrow: <ArrowRight />,
      className: "imageSlider",
      dots: true,
      slidesToShow: parseInt(colLg),
      infinite: this.props.images.length > this.props.columns,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: parseInt(colMd),
            infinite: this.props.images.length > colMd
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: parseInt(colSm),
            infinite: this.props.images.length > colSm
          }
        }
      ],
      swipeToSlide: true,
      focusOnSelect: true
    }

    const options = {
      settings: {
        autoplaySpeed: 0,
        disableWheelControls: true,
        overlayColor: `rgba(37, 38, 72, 0.9)`
      },
      caption: {
        showCaption: false
      },
      thumbnails: {
        showThumbnails: false
      },
      progressBar: {
        showProgressBar: false
      }
    }

    return (
      <SRLWrapper options={options}>
        <Slick {...settings}>
          {this.props.images.map(slide => <Figure key={slide._key} {...slide} /> )}
        </Slick>
      </SRLWrapper>
    )
  }
}

export default ImageSlider
