import React from "react"

import Calculator from "../../assets/svg/icon-calculator.svg"
import Car from "../../assets/svg/icon-car.svg"
import Coins from "../../assets/svg/icon-coins.svg"
import DoubleHelix from "../../assets/svg/icon-double-helix.svg"
import Factory from "../../assets/svg/icon-factory.svg"
import Hardhat from "../../assets/svg/icon-hardhat.svg"
import Health from "../../assets/svg/icon-health.svg"
import Plane from "../../assets/svg/icon-plane.svg"
import Presentation from "../../assets/svg/icon-presentation.svg"
import SpeechBubbles from "../../assets/svg/icon-speech-bubbles.svg"
import Speedometer from "../../assets/svg/icon-speedometer.svg"
import Tiers from "../../assets/svg/icon-tiers.svg"


const Icon = ({icon}) => {
  switch (icon) {
    case "icon-calculator":
      return <Calculator />

    case "icon-car":
      return <Car />

    case "icon-coins":
      return <Coins />

    case "icon-double-helix":
      return <DoubleHelix />

    case "icon-factory":
      return <Factory />

    case "icon-hardhat":
      return <Hardhat />

    case "icon-health":
      return <Health />

    case "icon-plane":
      return <Plane />

    case "icon-presentation":
      return <Presentation />

    case "icon-speech-bubbles":
      return <SpeechBubbles />

    case "icon-speedometer":
      return <Speedometer />

    case "icon-tiers":
      return <Tiers />

    default:
      return null
  }
}

export default Icon
