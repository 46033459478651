import React from "react"
import { Link } from "gatsby"

import styles from "./logo.module.scss"
import LogoSvg from "../../assets/svg/logo.svg"

const Logo = () => {
  return (
    <Link to="/" className={styles.logo}>
      <span className={styles.logoTitle}>Watershed homepage</span>
      <LogoSvg className={styles.logo} />
    </Link>
  )
}

export default Logo
