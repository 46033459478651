import React from "react"
import Figure from "./figure"
import Link from "./link"

import styles from "./inlineTestimonial.module.scss"

const InlineTestimonial = (props) => {
  return (
    <div className={styles.inlineTestimonial}>
      <Figure {...props.logo} />
      <div className={styles.inlineTestimonialContent}>
        <p>&ldquo;{props.testimonial}&rdquo;</p>
        <span>{props.name}, {props.company}</span>
      </div>
      {props.videoTestimonial && <div className={styles.inlineTestimonialLink}>
        <Link linkUrl={props.videoTestimonial} linkLabel="Watch the video testimonial" linkType="videoLink" />
      </div>}
    </div>
  )
}

export default InlineTestimonial
