import React from "react"

import styles from "./radarGraph.module.scss"

const RadarGraph = () => {
  return (
    <div className={styles.radarGraph}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 534 460" enableBackground="new 0 0 534 460">
        <g className={styles.group}>
          <polygon fill="#DE2B87" points="295.5,453.5 528.5,264.5 401.5,42.5 163.5,6.5 85.5,256.5  "/>
          <path fill="none" stroke="#DE2B87" strokeMiterlimit="10" d="M295.5,453.5 528.5,264.5 401.5,42.5 163.5,6.5 85.5,256.5 295.5,453.5 "/>
          <circle fill="#DE2B87" cx="401.5" cy="42.5" r="5.5"/>
          <circle fill="#DE2B87" cx="528.5" cy="263.5" r="5.5"/>
          <circle fill="#DE2B87" cx="295.5" cy="453.5" r="5.5"/>
          <circle fill="#DE2B87" cx="85.5" cy="256.5" r="5.5"/>
          <circle fill="#DE2B87" cx="163.5" cy="6.5" r="5.5"/>
        </g>
        <g className={styles.group}>
          <polygon fill="#97C672" points="446.5,212.5 284.5,5.5 4.5,58.5 58.5,328.5 296.5,455.5"/>
          <path fill="none" stroke="#97C672" strokeMiterlimit="10" d="M446.5,212.5 284.5,5.5 4.5,58.5 58.5,328.5 296.5,455.5 446.5,212.5 "/>
          <circle fill="#97C672" cx="5.5" cy="58.5" r="5.5"/>
          <circle fill="#97C672" cx="284.5" cy="5.5" r="5.5"/>
          <circle fill="#97C672" cx="446.5" cy="212.5" r="5.5"/>
          <circle fill="#97C672" cx="295.5" cy="454.5" r="5.5"/>
          <circle fill="#97C672" cx="57.5" cy="328.5" r="5.5"/>
        </g>
      </svg>
    </div>
  )
}

export default RadarGraph
