import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import { config } from "../helpers/clientConfig"

import styles from "./figure.module.scss"

const Figure = node => {
  const imgObject = node !== undefined && (node.asset || node.image.asset)
  const fit = node.fit ? node.fit : "contain"
  const fluidProps = getFluidGatsbyImage(imgObject, { maxWidth: 800 }, config)
  return (
    <>
      {imgObject.extension === "gif" ? (
        <figure data-size={node.size ? node.size.toLowerCase() : "large"}>
          <img
            src={imgObject.url}
            alt={node.alt || node.image.alt}
            width="100%"
            loading="lazy"
          />
          <figcaption>{node.caption || node?.image?.caption}</figcaption>
        </figure>
      ) : (
        <figure data-size={node.size ? node.size.toLowerCase() : "large"}>
          <Image
            fluid={fluidProps}
            objectFit={fit}
            alt={node.alt || node.image.alt}
          />
          <figcaption>{node.caption || node?.image?.caption}</figcaption>
        </figure>
      )}
    </>
  )
}

export default Figure
