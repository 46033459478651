import React from "react"
import { connect } from "react-redux"
import Video from "./videoModal"

const ModalConductor = (props, {isOpen, currentModal}) => {
  switch (props.currentModal) {
    case "video":
      return <Video {...props} isOpen={props.isOpen} url={props.url} />

    default:
      return null
  }
}

const mapStateToProps = state => ({
  isOpen: state.modal.showModal,
  currentModal: state.modal.modalType,
  url: state.modal.url ? state.modal.url : null,
})

export default connect(mapStateToProps)(ModalConductor)
