import React from "react"
import ReactPlayer from "react-player"

import styles from "./videoEmbed.module.scss"

const VideoEmbed = (props) => {
  return (
    <div className={styles.videoEmbed}>
      <ReactPlayer
        width="100%"
        height="100%"
        url={props.url}
        controls
      />
    </div>
  )
}

export default VideoEmbed