import React from "react"
import Button from "./button"

import styles from "./buttons.module.scss"

const Buttons = ({buttons}) => {
  return (
    <div className={styles.buttons}>
      {buttons.map(button =>
        <Button key={button._key} link={button.link} label={button.label} type={button.type} linkType={button._type} />
      )}
    </div>
  )
}

export default Buttons
