import React from "react"

import styles from "./radarPinkGraph.module.scss"

const RadarPinkGraph = () => {
  return (
    <div className={styles.radarPinkGraph}>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 1451.1 1098.1" enableBackground="new 0 0 1451.1 1098.1">
        <polygon className={styles.polygon} fill="none" stroke="#E02A88" strokeMiterlimit="10" points="663.5,357.5 660.9,420.2 739.9,453.6 818.2,420.9
          815.1,358.4 739.4,328.3 "/>
        <polygon className={styles.polygon} fill="none" stroke="#E02A88" strokeMiterlimit="10" points="578.7,453.2 589.6,327.2 739.1,271.9 887.8,329.2
          899.5,454.2 740.2,523.9 "/>
        <polygon className={styles.polygon} fill="none" stroke="#E02A88" strokeMiterlimit="10" points="492.9,487.6 518,297.9 738.9,219.2 957.7,301.2 983.6,488.7
          740.5,599.9 "/>
        <polygon className={styles.polygon} fill="none" stroke="#E02A88" strokeMiterlimit="10" points="403.2,523.6 448.5,269.4 738.7,169.9 1025,274.2
          1070.6,524.3 740.8,682.3 "/>
        <polygon className={styles.polygon} fill="none" stroke="#E02A88" strokeMiterlimit="10" points="309.5,561.2 381,241.8 738.5,123.6 1089.7,248.3
          1160.6,561.2 741.2,772.2 "/>
        <polygon className={styles.polygon} fill="none" stroke="#E02A88" strokeMiterlimit="10" points="211.3,600.5 315.6,215 738.3,80.1 1152.1,223.3 1253.8,599.4
          741.6,870.5 "/>
        <polygon className={styles.polygon} fill="none" stroke="#E02A88" strokeMiterlimit="10" points="108.5,641.8 252,188.9 738.2,39.2 1212.2,199.2 1350.4,638.9
          742,978.5 "/>
        <polygon className={styles.polygon} fill="none" stroke="#E02A88" strokeMiterlimit="10" points="0.6,685 190.3,163.6 738,0.5 1270.2,175.9 1450.5,680
          742.5,1097.6 "/>
        <g>
          <path className={styles.line} fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M738 0.5, 742.5 1097.6"/>
          <path className={styles.line} fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M0.6 685, 1270.2 175.9"/>
          <path className={styles.line} fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M190.3 163.6, 1450.5 680"/>
        </g>
        <g className={styles.points}>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M322.7,215.1c-0.7,2.5-4.4,4.4-8.3,4.4
            c-4,0-6.6-2.1-5.9-4.5c0.7-2.5,4.4-4.4,8.3-4.4C320.7,210.6,323.4,212.6,322.7,215.1z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M388.2,242.3c-0.6,2.5-4.3,4.6-8.3,4.5
            c-4,0-6.8-2.1-6.2-4.7c0.6-2.5,4.3-4.6,8.3-4.5C386,237.6,388.7,239.7,388.2,242.3z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M220.3,600.4c-1,3.8-5.9,7-10.8,7
            c-5,0-8.1-3.1-7.1-7c1.1-3.8,5.9-6.9,10.8-6.9C218.2,593.5,221.3,596.6,220.3,600.4z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M752.4,974.3c0,5.4-4.6,9.9-10.3,9.9
            c-5.8,0.1-10.4-4.3-10.4-9.7c0-5.4,4.6-9.8,10.3-9.9C747.7,964.6,752.3,968.9,752.4,974.3z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M1169.2,562.2c0.8,3.6-2.2,6.5-6.8,6.5
            c-4.6,0-9-2.9-9.8-6.5c-0.8-3.6,2.3-6.5,6.8-6.5C1164,555.7,1168.3,558.6,1169.2,562.2z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M1158.9,223.4c0.7,2.4-1.8,4.4-5.6,4.3
            c-3.8,0-7.3-2-8-4.4c-0.6-2.4,1.9-4.3,5.6-4.3C1154.7,219,1158.2,221,1158.9,223.4z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M744.7,80.2c0,2-2.8,3.6-6.3,3.6
            c-3.5,0-6.4-1.7-6.4-3.8c0-2,2.8-3.6,6.3-3.6C741.8,76.5,744.7,78.2,744.7,80.2z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M744.3,39.3c0,1.9-2.7,3.4-6.1,3.4
            c-3.4-0.1-6.2-1.6-6.2-3.5c0-1.9,2.8-3.4,6.1-3.3C741.6,35.8,744.3,37.4,744.3,39.3z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M991.7,488.7c0.5,3.3-2.8,6-7.2,6
            c-4.5,0-8.5-2.7-8.9-6.1c-0.4-3.3,2.8-6,7.2-6C987.2,482.7,991.2,485.4,991.7,488.7z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M749.9,682.3c0,4.1-4,7.5-9,7.5
            c-5,0-9.1-3.3-9.1-7.4c0-4.1,4-7.4,9-7.5C745.8,674.9,749.8,678.2,749.9,682.3z"/>
          <path display="inline" fill="none" stroke="#E02A88" strokeMiterlimit="10" d="M501.2,487.6c-0.4,3.4-4.5,6.1-9.1,6.1
            c-4.6,0-8-2.8-7.5-6.2c0.5-3.4,4.5-6.1,9.1-6.1C498.3,481.5,501.6,484.3,501.2,487.6z"/>
        </g>
        <g>
          <path className={styles.radar} fill="none" stroke="#E02A88" strokeWidth="2" strokeMiterlimit="10" d="M315.6,215 738.2,39.2 1212.2,199.2 983.6,488.7 740.8,682.3 492.9,487.6 315.6,215"/>
          <path className={styles.radar} fill="none" stroke="#E02A88" strokeWidth="2" strokeMiterlimit="10" strokeDasharray="20" d="M381,242.2 738.3,80.1 1152.1,223.3 1160.8,562.2 742,978.5 211.4,600.4 381,242.2"/>
        </g>
      </svg>
    </div>
  )
}

export default RadarPinkGraph
