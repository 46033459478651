import * as React from "react"

const Arrow = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38.1 29.8"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#e02a88"
      d="M38.1 14.9c0 1.1-.3 2.1-1.1 2.9L26.3 28.6c-.8.8-1.8 1.2-2.8 1.2-1 0-2-.4-2.8-1.2-1.6-1.5-1.6-4.1 0-5.6l4-4.1H4c-2.2 0-4-1.8-4-4s1.8-4 4-4h20.6l-4-4.1c-1.5-1.6-1.5-4.1 0-5.7 1.6-1.6 4.1-1.5 5.6 0L37 11.9c.8.8 1.2 1.9 1.1 3z"
    />
  </svg>
)

export default Arrow
