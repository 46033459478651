import React from 'react'
import IframeEmbed from './iframeEmbed'

import styles from "./iframeGroup.module.scss"

const IframeGroup = (props) => {
  let layoutStyling;

  if (props.layout === '1') layoutStyling = styles.first
  else if (props.layout === '2') layoutStyling = styles.second
  else layoutStyling = styles.third

  return (
    <div className={`${layoutStyling}`}>
      {props.iframeGroupArray.map(iframe => <IframeEmbed key={iframe._key} {...iframe} />)}
    </div>
  )
}

export default IframeGroup
