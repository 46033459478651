import React from "react"
import ReactPlayer from "react-player"

import { createMarkup } from "../helpers/createMarkup"
import styles from "./iframeEmbed.module.scss"
import cx from "classnames"

const IframeEmbed = props => {
  const setLength = (custom, aspect, i) => {
    if (custom !== undefined) custom = `${custom.width}:${custom.height}`
    const aspectRatio = custom || aspect || "16:9"

    return aspectRatio.split(":")[i]
  }

  const aspectWidth = setLength(props.custom, props.aspect, 0)
  const aspectHeight = setLength(props.custom, props.aspect, 1)
  let aspectRatio = `${(aspectHeight / aspectWidth) * 100}%`
  let fixedHeight = 0

  if (props.height) {
    aspectRatio = 0
    fixedHeight = `${props.height}px`
  }
  const customMargin = props.narrowWidth
  const allowRedirect = "allow-top-navigation allow-scripts allow-forms"
  // Set the iframe depending on author's decisions
  const UrlIframe = () => {
    if (!props.allowRedirect) {
      return (
        <iframe
          title={props.title}
          className={styles.iframe}
          allow="fullscreen"
          frameBorder="0"
          src={props.url}
        ></iframe>
      )
    } else {
      return (
        <iframe
          title={props.title}
          className={styles.iframe}
          allow="fullscreen"
          frameBorder="0"
          src={props.url}
          sandbox={allowRedirect}
        ></iframe>
      )
    }
  }
  const CodeIframe = () => (
    <div
      className={styles.iframe}
      dangerouslySetInnerHTML={createMarkup(props.iframeHtml)}
    ></div>
  )
  const Iframe = () => (props.iframeHtml ? CodeIframe() : UrlIframe())

  switch (props._type) {
    case "videoEmbed":
      return (
        <div
          className={cx(styles.iframeWrapper, customMargin && styles.narrower)}
          style={{
            paddingTop: aspectRatio,
            height: fixedHeight,
            margin: customMargin,
          }}
        >
          <ReactPlayer
            className={styles.iframe}
            url={props.url}
            width="100%"
            height="100%"
          />
        </div>
      )

    default:
      return (
        <div>
          {props.displayTitle ? <h3>{props.title}</h3> : ""}
          <div
            className={styles.iframeWrapper}
            style={{ paddingTop: aspectRatio, height: fixedHeight }}
          >
            <Iframe />
          </div>
        </div>
      )
  }
}

export default IframeEmbed
