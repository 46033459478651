import React from "react"
import { StaticQuery, graphql } from "gatsby"
import FormattedLink from "./link"
import SocialIcon from "./socialIcon"
import Logo from "./logo"
import LTGLogo from "../../assets/svg/logoLtg.svg"

import styles from "./footer.module.scss"

const Footer = () => {
  let copyrightYear = new Date()
  copyrightYear = copyrightYear.getFullYear()

  return (
    <StaticQuery
      query={graphql`
        {
          sanitySocialMedia {
            _rawPlatform
          }
          sanityFooterNavigation {
            _rawAbout
            _rawExpertise
            _rawProduct
            _rawResources
          }
        }
      `}
      render={data => {
        const RenderGridLinks = props => {
          const { links, title } = props
          return (
            <div className="gridItem medium-6 large-3">
              <p className={styles.footerTitle}>{title}</p>
              <ul>
                {links.map(item => (
                  <li key={item._key}>
                    <FormattedLink
                      {...item}
                      linkUrl={item.link}
                      linkLabel={item.linkLabel}
                      unstyled
                    />
                  </li>
                ))}
              </ul>
              {props.children}
            </div>
          )
        }
        return (
          <footer className={styles.footer}>
            <nav className="grid" aria-label="footer">
              <div className="gridItem">
                <Logo />
              </div>
              {/* Titles can be done dynamic in the future and then code can be refactored again */}
              <RenderGridLinks
                links={data.sanityFooterNavigation._rawProduct.links}
                title={"Product"}
              />
              <RenderGridLinks
                links={data.sanityFooterNavigation._rawExpertise.links}
                title={"Expertise"}
              />
              <RenderGridLinks
                links={data.sanityFooterNavigation._rawResources.links}
                title={"Resources"}
              />
              <RenderGridLinks
                links={data.sanityFooterNavigation._rawAbout.links}
                title={"About Us"}
              >
                {data.sanitySocialMedia._rawPlatform && (
                  <ul className={styles.footerSocialList}>
                    {data.sanitySocialMedia._rawPlatform.map(platform => (
                      <li key={platform._key}>
                        <a
                          href={platform.url}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          <span>Link to {platform.platform}</span>
                          <SocialIcon type={platform.platform} />
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </RenderGridLinks>
              <div className="gridItem">
                <p className={styles.footerCopyright}>
                  <span>&copy; {copyrightYear} Watershed Systems, Inc</span>
                  <span>
                    Part of{" "}
                    <a
                      href="https://ltgplc.com/"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      Learning Technologies Group <LTGLogo />
                    </a>
                  </span>
                </p>
              </div>
            </nav>
          </footer>
        )
      }}
    />
  )
}

export default Footer
