import React from "react"
import { Link } from "gatsby"
import { LinkTest, LinkFormat } from "../helpers/linkTest"
import { connect } from "react-redux"
import { showVideoModal } from "../store/actions"

import styles from "./button.module.scss"

const Button = (props, {dispatch, isOpen, url}) => {
  const handleClick = (evt) => {
    evt.preventDefault()
    props.dispatch(showVideoModal({isOpen: !isOpen, url: props.link}))
  }
  const clickCallback = props.linkType === "videoLink" ? {["onClick"]: (evt) => { handleClick(evt) }} : null
  const newTab = props.newTab ? {["target"]: "_blank"} : null
  const noFollow = props.noFollow ? {["rel"]: "nofollow noopener noreferrer"} : null
  const id = props.id ? {["id"]: props.id} : null
  const link = props.link._type ? props.link.asset.url : props.link
  const linkType = LinkTest(link)

  return (
    <>
      {linkType === "internal" ? (
        <Link className={styles.button} {...id} to={link} data-type={props.type}>{props.label}</Link>
      ) : (
        <a href={LinkFormat(link)} className={styles.button} {...id} {...clickCallback} {...newTab} {...noFollow} data-type={props.type}>{props.label}</a>
      )}
    </>
  )
}

export default connect((state) => ({
  isOpen: state.modal.showModal,
  url: state.modal.url
}), null)(Button)
