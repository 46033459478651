import React from "react"

import styles from "./table.module.scss"

const Table = props => {
  const header = props.header ? props?.table?.rows[0] : undefined
  const body = props.header ? props?.table?.rows?.slice(1) : props?.table?.rows

  const linkTest = text => {
    const words = text.split(" ")
    let newWords = []
    words.forEach(word => {
      const link = new RegExp("^(?:[a-z]+:)?//", "i")
      link.test(word)
        ? newWords.push(
            `<a href='${word}' target="_blank" rel="nofollow noopener noreferrer">${word}</a>`
          )
        : newWords.push(word)
    })
    return { __html: newWords.join(" ") }
  }

  return (
    <div>
      {props.tableTitle && <h3>{props.tableTitle}</h3>}
      <table className={styles.table}>
        {header && (
          <thead>
            <tr>
              {header.cells.map((cell, i) => (
                <th key={`${header._key}_${i}`}>{cell}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {body?.map(row => (
            <tr key={row._key}>
              {row.cells.map((cell, i) => {
                return header ? (
                  <td
                    key={`${row._key}_${i}`}
                    data-header={header.cells[i]}
                    dangerouslySetInnerHTML={linkTest(cell)}
                  />
                ) : (
                  <td
                    key={`${row._key}_${i}`}
                    dangerouslySetInnerHTML={linkTest(cell)}
                  />
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
